import { ReactNode, useEffect, useState } from "react";

import { ApolloProvider } from "@apollo/client";
import { NoSsr } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { Toaster } from "react-hot-toast";

import { ColumnLayout } from "shared/layouts/ColumnLayout";
import { ColorModeProvider } from "shared/providers/ColorModeProvider";

import Layout from "../components/Layout/Layout";
import LayoutWithoutNavbarFleet from "../components/Layout/LayoutWithoutNavbarFleet";
import PageHead from "../components/PageHead";
import client from "../lib/apollo-client";
import { AuthProvider } from "../providers/AuthProvider";
import { ErrorProvider } from "../providers/ErrorProvider";

import type { AppProps } from "next/app";

import "../styles/globals.css";
import "../styles/print.css";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);
const routesRequireColumnLayout = ["/login", "/delete-my-data"];
const UNAUTHENTICATED_ROUTES = new Set([
  ...routesRequireColumnLayout,
  "/accept",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/approve",
  "/verify",
  "/pay",
  "/web",
  "/welcome",
  "/landing1",
  "/requests",
]);

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const [LDProvider, setLDProvider] = useState<
    (({ children }: { children: ReactNode }) => JSX.Element) | null
  >(null);

  useEffect(() => {
    async function initializeLD() {
      if (!process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY) return;
      const LDProviderComponent = await asyncWithLDProvider({
        clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY,
        options: {
          streaming: true,
        },
      });
      setLDProvider(() => LDProviderComponent);
    }

    initializeLD();
  }, [process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY]);

  let routeRequiresAuth = true;
  for (const routeToTest of Array.from(UNAUTHENTICATED_ROUTES)) {
    routeRequiresAuth =
      routeRequiresAuth && !router.asPath.startsWith(routeToTest);
  }

  const showColunmLayoutWithLogo = routesRequireColumnLayout.some((route) =>
    router.asPath.includes(route)
  );

  const getLayout =
    Component.getLayout ??
    ((page) =>
      routeRequiresAuth ? (
        <Layout>{page}</Layout>
      ) : showColunmLayoutWithLogo ? (
        <ColumnLayout showLogo>{page}</ColumnLayout>
      ) : (
        <LayoutWithoutNavbarFleet>{page}</LayoutWithoutNavbarFleet>
      ));

  if (!LDProvider) {
    return false;
  }

  return (
    <NoSsr>
      <PageHead />
      <ColorModeProvider>
        <ErrorProvider>
          <LDProvider>
            <AuthProvider routeRequiresAuth={routeRequiresAuth}>
              <ApolloProvider client={client}>
                <Toaster
                  position="top-right"
                  toastOptions={{
                    duration: 10000,
                    style: {
                      fontWeight: 700,
                      maxWidth: "750px",
                    },
                  }}
                />
                <Elements stripe={stripePromise}>
                  {getLayout(<Component {...pageProps} />)}
                </Elements>
              </ApolloProvider>
            </AuthProvider>
          </LDProvider>
        </ErrorProvider>
      </ColorModeProvider>
      <Analytics />
      <SpeedInsights />
    </NoSsr>
  );
}

export default App;
