import { getAuthenticatedRequestHeaders } from "shared/services/serviceUtils";

import {
  AcceptFleetInviteForm,
  Fleet,
  FleetRoles,
} from "../../../../backend/src/fleets/fleet.entity";
import { Account } from "../components/CreateAccount";

export interface FleetInvitedUser {
  id: number;
  email: string;
  role: FleetRoles;
}

export interface VerificationRequirements {
  email: boolean;
  phone: boolean;
}

export const acceptInvite = async (inviteForm: AcceptFleetInviteForm) => {
  const options = {
    method: "POST",
    headers: await getAuthenticatedRequestHeaders(),
    body: JSON.stringify({ ...inviteForm }),
  };

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/fleet/invite`,
    options
  );
  const body = await response.json();
  if (!response.ok) {
    console.error("acceptInvite() error response from backend:", body);
    throw new Error(body.message);
  }
  return body.token;
};

export const loginWithInvite = async (
  inviteForm: AcceptFleetInviteForm,
  email: string,
  password: string
): Promise<string> => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...inviteForm, email, password }),
  };

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/fleet/login/invite`,
    options
  );
  const body = await response.json();
  if (!response.ok) {
    console.error("loginWithInvite() error response from backend:", body);
    throw new Error(body.message);
  }
  return body.token;
};

export const getVerificationRequirements = async (
  email: string,
  phone: string,
  company: string,
  name: string,
  isInvite: boolean
): Promise<
  | VerificationRequirements
  | {
      message: string;
      error: string | number;
      statusCode: number;
    }
> => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${
      process.env.NEXT_PUBLIC_APIV2_URL
    }/auth/verifications?email=${encodeURIComponent(
      email
    )}&phone=${encodeURIComponent(phone)}&company=${encodeURIComponent(
      company
    )}&name=${encodeURIComponent(name)}&isInvite=${isInvite}`,
    options
  );

  if (!response.ok) {
    const body = await response.json();
    console.error(
      "getVerificationRequirements() error response from backend:",
      body
    );
    return body as Promise<{
      message: string;
      error: string | number;
      statusCode: number;
    }>;
  }

  return response.json() as Promise<VerificationRequirements>;
};

export const createUser = async (
  account: Account | AcceptFleetInviteForm | undefined
) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...account }),
  };
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/user/fleet`,
    options
  );
  if (!response.ok) {
    return await response.json();
  }
  return (await response.json()) as { token: string; errorCodes: null };
};

export async function sendEmailVerificationCode(email: string) {
  const options = {
    method: "POST",
    headers: await getAuthenticatedRequestHeaders(),
    body: JSON.stringify({ email }),
  };
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/email/verify`,
    options
  );
  if (!response.ok) {
    return response.text();
  }
  return await response.json();
}

export async function sendPhoneVerificationCode(phone: string) {
  const options = {
    method: "POST",
    headers: await getAuthenticatedRequestHeaders(),
    body: JSON.stringify({ phone }),
  };
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/phone/verify`,
    options
  );
  if (!response.ok) {
    return response.text();
  }
  return await response.json();
}

export async function verifyPhoneCode(
  phone: string,
  verificationId: string,
  code: string
) {
  const options = {
    method: "PUT",
    headers: await getAuthenticatedRequestHeaders(),
    body: JSON.stringify({ verificationId, phone, code }),
  };
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/phone/verify`,
    options
  );
  return await response.json();
}

export async function createFleet(
  fleetId: number,
  name: string,
  company?: string,
  isInsuranceFleet?: boolean
): Promise<Fleet> {
  const options = {
    method: "POST",
    headers: await getAuthenticatedRequestHeaders(),
    body: JSON.stringify({ fleet: { name, company, isInsuranceFleet } }),
  };

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/fleet/${fleetId}`,
    options
  );
  const res = await response.json();

  if (!response.ok) {
    console.error(res);
  }

  return res;
}
